export const DEFAULT_COUNTRY_CODE = 'x'
export const DEFAULT_CITY_NAME = 'y'

export function makeListRoute() {
  const filterConditionStore = useFilterConditionsStore()
  const countryCode = filterConditionStore.filterConditions.selectedCity?.countryCode || DEFAULT_COUNTRY_CODE
  const cityName = (filterConditionStore.filterConditions.selectedCity?.destinationName || DEFAULT_CITY_NAME)
    .replace(/\s/g, '-')
    .toLowerCase()
  const { locale } = useLocale()

  return `${countryCode}/${cityName}/${locale.value}.html`
}

export function makeDetailRoute(hotelName: string) {
  hotelName = hotelName.replace(/\s/g, '-').toLowerCase()
  const filterConditionStore = useFilterConditionsStore()
  const countryCode = filterConditionStore.filterConditions.selectedCity?.countryCode || DEFAULT_COUNTRY_CODE
  const cityName = (filterConditionStore.filterConditions.selectedCity?.destinationName || DEFAULT_CITY_NAME)
    .replace(/\s/g, '-')
    .toLowerCase()
  const { locale } = useLocale()

  return `${countryCode}/${cityName}/${hotelName}.${locale.value}.html`
}
